import { Icon } from '@/components/atoms/Icons';
import { ReactElement } from 'react';

interface OverlayProps {
  children: ReactElement;
  setIsOpen: (isOpen: boolean) => void;
  setTypeUpload: (arg0: string) => void;
  isOpen: boolean;
}
export const Overlay = (props: OverlayProps) => {
  const { children, isOpen, setIsOpen, setTypeUpload } = props;

  return (
    <div className="z-50 absolute">
      {isOpen && (
        <div
          role="button"
          tabIndex={0}
          aria-label="Ir a la página de destino"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setIsOpen(false);
              setTypeUpload('default');
            }
          }}
          onClick={() => {
            setIsOpen(false);
            setTypeUpload('default');
          }}
          className="fixed left-0 top-0 flex w-full h-full px-10 items-center justify-center bg-[#1F0E53]/80"
        >
          <div
            role="button"
            tabIndex={0}
            aria-label="Ir a la página de destino"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.stopPropagation();
              }
            }}
            onClick={(e) => e.stopPropagation()}
            className="relative rounded-[1.25rem] bg-white -translate-y-16 max-tablet-xsm:mt-10"
          >
            <Icon
              width="1.1rem"
              name="close"
              className="w-10 absolute right-0 top-0 mr-5 mt-4 cursor-pointer z-50"
              onClick={() => {
                setIsOpen(false);
                setTypeUpload('default');
              }}
            />

            <div className="pointer-events-auto">{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};
