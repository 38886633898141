import { Icon } from '@/components/atoms/Icons';
import { Animation } from '@/components/atoms/Animations';

interface ErrorPdfProps {
  setType: (arg0: string) => void;
  setTypeUpload: (arg0: string) => void;
}

export const ErrorPdf = (props: ErrorPdfProps) => {
  const { setType, setTypeUpload } = props;
  return (
    <div className="relative">
      <div className="rounded-[1.25rem] bg-white relative p-3 max-mobile-md:w-[85%]  max-tablet-md:w-[80%] max-desktop-xl:w-[70%] w-[55.6%] transition-width duration-300 ease-in-out mx-auto shadow-lg">
        <Icon
          className="absolute bottom-0 -z-10 translate-x-[-4rem] translate-y-[3.4rem]"
          width="9rem"
          height="8rem"
          name="fourLineGray"
        />
        <Icon
          className="absolute bottom-0 right-0 -z-10 translate-x-[2.5rem] translate-y-16 rotate-90"
          width="8rem"
          height="8rem"
          name="pointDecorationYellow"
        />
        <div className="flex gap-10 max-tablet-sm:gap-0 max-tablet-sm:flex-col items-center h-auto justify-between px-6 rounded-[1.25rem] border-2 border-dashed py-10  max-tablet-sm:py-1 max-tablet-xs:py-0">
          <Animation
            className="w-[8rem] h-[8rem] flex-shrink-0 max-tablet-sm:scale-[0.8] max-tablet-sm:mt-2 max-mobile-lg:mt-0"
            name="error"
          />

          <div className="text-headingSm min-w-[15rem] translate-x-[-0.5rem] max-tablet-sm:-translate-x-0 max-tablet-sm:-translate-y-2 w-full max-tablet-sm:text-center">
            <h1 className="font-bold font-trebuchet leading-8 text-[1.4rem]">
              Ha ocurrido un error procesando los datos
            </h1>
            <h1 className="mt-3 font-light text-neutral-50 font-nunito text-bodyXs">
              No se ha podido completar el procesamiento de la data, por favor
              intenta nuevamente mas tarde.
            </h1>
          </div>

          <div
            onClick={() => {
              setType('default');
              setTypeUpload('default');
            }}
            className="flex items-center justify-center p-3 cursor-pointer max-tablet-sm:-translate-x-0 max-tablet-sm:my-3  -translate-x-6 rounded-full bg-[#E8E8E8]"
          >
            <Icon width="2rem" height="2rem" name="return" />
          </div>
        </div>
      </div>
    </div>
  );
};
