import { Opacity } from '@mui/icons-material';
import { Animation } from '@/components/atoms/Animations';
import { useAuthNavigation } from '@/hooks/useAuthNavigation';
import { useState, useEffect } from 'react';

export default function SignatureBeforeLoginResponsive() {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { handleLoginAndVerifyNavigation, handleRegisterAndVerifyNavigation } =
    useAuthNavigation();

  const handleSignUpLogin = (type: string) => {
    if (type === 'login') {
      handleLoginAndVerifyNavigation();
    } else {
      handleRegisterAndVerifyNavigation();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1100);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="relative">
      <div className="w-full h-full absolute z-10 flex items-center justify-center">
        <Animation
          className={`${isVisible ? 'opacity-0 -z-50' : 'opacity-100'} pointer-events-none mt-[2rem] w-[11rem] absolute h-[11rem]`}
          name="check"
        />
      </div>
      <div
        className={`${isVisible ? 'opacity-100' : 'opacity-0'} rounded-t-[1.25rem] max-tablet-xsm:w-full bg-white relative p-3 max-mobile-md:w-[85%]  max-tablet-md:w-[80%] max-desktop-xl:w-[70%] w-[55.6%] transition-width duration-300 ease-in-out mx-auto`}
      >
        <div className="flex max-tablet-sm:flex-col items-center h-auto justify-between px-6 rounded-[1.25rem]  py-10  max-tablet-sm:py-1 max-tablet-xs:py-0">
          <div className="text-headingSm min-w-[15rem]  max-[960px]:text-center max-[960px]:py-5  max-tablet-sm:py-1">
            <h1 className="font-bold font-trebuchet leading-8 text-[1.2rem] px-6">
              El documento está listo para ser firmado
            </h1>
            <h1 className="mt-3 font-light text-neutral-80 font-nunito text-bodyXs">
              Accede con tu cuenta para proceder con la firma. Si no tienes una,
              presiona el botón de crear cuenta.
            </h1>
          </div>
          <div className="flex max-mobile-md:flex-col items-center z-50 gap-5 max-mobile-md:gap-0 mt-2">
            <button
              onClick={() => handleSignUpLogin('login')}
              className="w-[11rem] h-[2.5rem] text-bodySm max-tablet-sm:mb-2  rounded-full text-primary-550  border border-primary-550"
            >
              Iniciar sesión
            </button>
            <button
              onClick={() => handleSignUpLogin('signUp')}
              className="w-[11rem] h-[2.5rem] text-bodySm max-tablet-sm:mb-2  rounded-full text-white  bg-primary-550"
            >
              Crear cuenta
            </button>
          </div>
        </div>
      </div>
      <hr className="-mb-4" />
    </div>
  );
}
