export const pdfToString = (file: File | null): Promise<{ name: string } | null> => {
  return new Promise((resolve) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        try {
          localStorage.clear();
          localStorage.setItem('pdfDocument', base64data);
          resolve({ name: file.name });
        } catch (error) {
          console.error('Error saving to localStorage:', error);
          resolve(null); 
        }
      };

      reader.readAsDataURL(file); 
    } else {
      console.log('No file selected');
      resolve(null);
    }
  });
};

