import { Animation } from '@/components/atoms/Animations';
import { useState, useEffect } from 'react';
import { Overlay } from '@/components/molecules/Overlay';
import { PDFViewer } from '@/components/molecules/PdfViewer';
import { useResponsive } from '@/hooks/useResponsive';
import { useAuthNavigation } from '@/hooks/useAuthNavigation';
import SignatureBeforeLoginResponsive from './SignatureBeforeLoginResponsive';

interface LoginDigitoProps {
  setTypeUpload: (arg0: string) => void;
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  pdfName: string;
}

export const SignatureBeforeLogin = (props: LoginDigitoProps) => {
  const { setTypeUpload, isOpen, setIsOpen, pdfName } = props;
  const { isMobileBeforeSign } = useResponsive();

  const { handleLoginAndVerifyNavigation, handleRegisterAndVerifyNavigation } =
    useAuthNavigation();

  const handleSignUpLogin = (type: string) => {
    if (type === 'login') {
      handleLoginAndVerifyNavigation();
    } else {
      handleRegisterAndVerifyNavigation();
    }
  };

  return (
    <Overlay
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setTypeUpload={setTypeUpload}
    >
      <div className="flex max-tablet-xsm:flex-col h-[37rem] max-tablet-xsm:h-[40rem] w-full max-w-5xl mx-auto overflow-hidden">
        {isMobileBeforeSign && <SignatureBeforeLoginResponsive />}

        <div className="flex flex-col items-center rounded-lg border-5 border-neutral45 bg-neutral5 p-5 flex-grow">
          <div className="my-6 relative flex max-tablet-xsl:none w-full items-center justify-between rounded-full bg-black px-6  max-tablet-xsl:px-0">
            <span className="font-nunito font-semibold text-white max-tablet-xsl:opacity-0">
              Previsualización{' '}
              <span className="max-tablet-md:opacity-0">del archivo</span>
            </span>
            <span className="max-tablet-xsl:px-10 w-[12rem] overflow-hidden whitespace-nowrap overflow-ellipsis font-nunito max-tablet-xsl:absolute  max-tablet-xsl:w-full  max-tablet-xsl:text-center font-thin italic text-white opacity-60">
              {pdfName}
            </span>
          </div>
          <PDFViewer
            className="flex-grow w-full shadow-2xl"
            classNameIframe="w-[35rem] max-tablet-sm:w-[30rem] max-tablet-xsl:w-[25rem] max-tablet-xsm:w-full"
          />
        </div>

        {!isMobileBeforeSign && (
          <div className="px-5 -translate-x-2 flex items-center">
            <div className="flex flex-col items-center">
              <Animation
                className="w-[13rem] h-[13rem] pointer-events-none mt-[2rem] max-tablet-xsl:w-[11rem] max-tablet-xsl:h-[11rem]"
                name="check"
              />

              <h1 className="mb-3 -mt-4 text-center text-black text-headingXs font-trebuchet">
                El documento está listo para ser firmado
              </h1>
              <p className="text-center leading-6 mb-5 max-tablet-xsl:text-[0.9rem] text-neutral80">
                Accede con tu cuenta para proceder con la firma. Si no tienes
                una, presiona el botón de crear cuenta.
              </p>
              <div className="flex flex-wrap gap-3 mb-20 justify-center">
                <button
                  className="text-primary-550 text-bodyMd w-[13rem] h-[3.25rem] border border-primary-550 rounded-full max-tablet-xsl:w-[11rem] max-tablet-xsl:h-[2.8rem]"
                  onClick={() => handleSignUpLogin('login')}
                >
                  Iniciar sesión
                </button>
                <button
                  className="text-white text-bodyMd w-[13rem] h-[3.25rem] bg-primary-550 rounded-full  max-tablet-xsl:w-[11rem] max-tablet-xsl:h-[2.8rem]"
                  onClick={() => handleSignUpLogin('signUp')}
                >
                  Crear cuenta
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Overlay>
  );
};
