'use client';
import { useUser } from '@auth0/nextjs-auth0/client';
import Stack from '@mui/joy/Stack';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useUserTrackIdentify } from '@/hooks/useUserTrackIdentify';
import { HomeLocal } from '@/components/home/HomeLocal';
import { Template } from '@/components/templates/Template';
import { useEffect } from 'react';

export default function Home() {
  const user = useUser();
  const router = useRouter();
  useUserTrackIdentify(user.user?.sub || '', user.user?.email || '');

  useEffect(() => {
    const storedPdf = localStorage.getItem('pdfDocument');
    if (storedPdf && user.user) {
      router.push('/signature');
    }
  }, [user.user]);

  return (
    <Template>
      <HomeLocal />
      {/* <Stack margin={2} width={600}>
        {user.isLoading && <div>Loading...</div>}

        {!user.user && (
          <>
            <br />
            <br />
            <h2>User Status</h2>
            <div>{user.user}</div>
            <Stack direction={'column'}>
              <Link href={'signatures'}>Firmas</Link>
              <Link href={'certificates'}>Certificados</Link>
              <Link href={'user/login-history'}>
                Historial de inicio de sesión
              </Link>
              <Link href={'user/authorized-apps'}>
                Autorización de uso aplicaciones
              </Link>

              <Link href={'/certificates/issuing'}>
                Descargar Certificado Intermedio
              </Link>
              <Link href={'/certificates/root'}>
                Descargar Certificado Raiz
              </Link>
            </Stack>
          </>
        )}
      </Stack> */}
    </Template>
  );
}
