import { Icon } from '@/components/atoms/Icons';
import { RefObject } from 'react';

interface DropAndUploadProps {
  inputRef?: RefObject<HTMLInputElement>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  triggerFileUpload: () => void;
}
export const DropAndUpload = (props: DropAndUploadProps) => {
  const { inputRef, handleFileChange, triggerFileUpload } = props;

  return (
    <div className="relative">
      <div className="rounded-[1.25rem] bg-white relative p-3 max-mobile-md:w-[85%]  max-tablet-md:w-[80%] max-desktop-xl:w-[70%] w-[55.6%] transition-width duration-300 ease-in-out mx-auto shadow-lg">
        <Icon
          className="absolute bottom-0 -z-10 translate-x-[-4rem] translate-y-[3.4rem]"
          width="9rem"
          height="8rem"
          name="fourLineGray"
        />
        <Icon
          className="absolute bottom-0 right-0 -z-10 translate-x-[2.5rem] translate-y-16 rotate-90"
          width="8rem"
          height="8rem"
          name="pointDecorationYellow"
        />
        <div className="flex max-tablet-sm:flex-col items-center h-auto justify-between px-6 rounded-[1.25rem] border-2 border-dashed py-10  max-tablet-sm:py-1 max-tablet-xs:py-0">
          <Icon
            width="5.9rem"
            height="6.8rem"
            name="pdfRed"
            className="flex-shrink-0 max-tablet-sm:scale-[0.8] max-tablet-sm:mt-2 max-mobile-lg:mt-0"
          />

          <div className="text-headingSm min-w-[15rem] ml-10 mr-5  max-[960px]:text-center max-[960px]:py-5  max-tablet-sm:py-1  min-w-[200px]">
            <h1 className="font-bold font-trebuchet leading-8 text-[1.4rem]">
              Arrastra y suelta un documento aquí para firmarlo
            </h1>
            <h1 className="mt-3 font-light text-neutral-50 font-nunito text-bodyXs">
              El formato debe ser PDF.
            </h1>
          </div>

          <div className="flex flex-col items-center">
            <button
              className="w-[14.37rem] text-bodySm max-tablet-sm:mb-2 max-tablet-sm:scale-[0.8] rounded-full text-white h-[3.44rem] bg-primary-550"
              onClick={triggerFileUpload}
            >
              Seleccionar Archivo
            </button>
            <input
              ref={inputRef}
              type="file"
              className="hidden"
              onChange={(event) => handleFileChange(event)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
