interface PDFViewerProps {
  className?: string;
  width?: string;
  height?: string;
  classNameIframe?: string;
}

export const PDFViewer = (props: PDFViewerProps) => {
  const { className, classNameIframe, width = '100%', height = '100%' } = props;

  const pdfUrl = localStorage.getItem('pdfDocument');

  const pdfError = '@/public/error.pdf';
  const pdf = pdfUrl ? pdfUrl : pdfError;

  return (
    <div className={`relative ${className} `} style={{ width, height }}>
      <div className="pointer-events-none absolute w-full h-full rounded-t-[0.7rem] border-[1rem] border-b-[0.5rem] border-r-[1.3rem] border-white" />
      <iframe
        title="Vista previa firma"
        width="100%"
        height="100%"
        src={`${pdf}#toolbar=0`}
        className={`rounded-t-[0.9rem] border-none ${classNameIframe}`}
      />
    </div>
  );
};
