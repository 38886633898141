import { Icon } from '@/components/atoms/Icons';

interface CardProps {
  backgroundColor: string;
  icon: string;
  title: string;
  text: string;
}

export const Card = (props: CardProps) => {
  const { backgroundColor, icon, title, text } = props;
  return (
    <div className="w-[17.4rem] flex-shrink-0 font-sohne font-bold h-[16.8rem] rounded-[0.9rem] border">
      <div
        className={`${backgroundColor} rounded-[0.9rem] relative h-[10.3rem] flex justify-center items-center`}
      >
        <div className="absolute rounded-[0.3rem] -ml-[10rem] text-center mt-[7rem] w-[5.16rem] h-[1.63rem] bg-white">
          {title}
        </div>

        <Icon name={icon as 'pdfRed'} />
      </div>
      <div className="flex justify-center items-center h-[6.5rem] px-5">
        <p>{text}</p>
      </div>
    </div>
  );
};
