import { useEffect, useRef, useState } from 'react';
import { pdfToString } from './usePdftoStorage';

export const useDragAndDrop = () => {
  const [pdfData, setPdfData] = useState<string>("");
  const [type, setType] = useState<string>('default');
  const dropZoneRef = useRef<HTMLDivElement>(null);

  const validateFile = (file: File) => {
    const isValid = file.name.toLowerCase().endsWith('.pdf');
    const isFileSizeValid = file.size < 1024 * 1024 * 100; // 100 MB
    return isValid && isFileSizeValid;
  };

  const handleFileUpload = async (file: File) => {
    try {
      const pdfInfo = await pdfToString(file);
      if (pdfInfo) {
        setPdfData(pdfInfo.name);
      }
    } catch (error) {
      console.error('Error al procesar el PDF:', error);
    }
  };


    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      setType('dragging');
    };

    const handleDragLeave = (event: DragEvent) => {
      event.preventDefault();
      setType('default');
    };

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      const dropZone = dropZoneRef.current;
      const isDropOutside = dropZone && !dropZone.contains(event.target as Node);

      if (isDropOutside) {
        setType('default');
      } else {
        const fileList = event.dataTransfer?.files;
        if (fileList && fileList.length > 0){
              if(validateFile(fileList[0])) {
                setType('loading');
                handleFileUpload(fileList[0]);
          
              }else {
                setType('error');
              }
        } else{
          setType('default');
        } 
        }
      }
      useEffect(() => {
        document.addEventListener('dragover', handleDragOver);
        document.addEventListener('dragleave', handleDragLeave);
        document.addEventListener('drop', handleDrop);
    
        return () => {
          document.removeEventListener('dragover', handleDragOver);
          document.removeEventListener('dragleave', handleDragLeave);
          document.removeEventListener('drop', handleDrop);
        };
      }, []);

   
 

  return { dropZoneRef, type, setType, pdfData };
};