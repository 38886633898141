import { ReactElement, useEffect, useState } from 'react';
import { DropAndUpload } from '@/components/molecules/signature/DropAndUpload';
import { ErrorPdf } from '@/components/molecules/signature/ErrorPdf';
import { LoadingPdf } from '@/components/molecules/signature/LoadingPdf';
import { useDragAndDrop } from '@/hooks/useDragAndDrop';
import { useFileUpload } from '@/hooks/useFileUpload';
import { useRouter } from 'next/router';
import { useUser } from '@auth0/nextjs-auth0/client';
import { SignatureBeforeLogin } from '@/components/molecules/signature/SignatureBeforeLogin';

export const HeroPdfStatus = () => {
  const [myState, setMyState] = useState<string>('default');
  const [isOpen, setIsOpen] = useState(false);
  const { dropZoneRef, type, setType, pdfData } = useDragAndDrop();
  const {
    inputRef,
    handleFileChange,
    triggerFileUpload,
    typeUpload,
    setTypeUpload,
    pdfDataUpload,
  } = useFileUpload();
  const router = useRouter();
  const user = useUser();

  const lookupState: Record<string, ReactElement> = {
    default: (
      <DropAndUpload
        inputRef={inputRef}
        handleFileChange={handleFileChange}
        triggerFileUpload={triggerFileUpload}
      />
    ),
    dragging: (
      <DropAndUpload
        inputRef={inputRef}
        handleFileChange={handleFileChange}
        triggerFileUpload={triggerFileUpload}
      />
    ),
    error: <ErrorPdf setType={setType} setTypeUpload={setTypeUpload} />,
    loading: <LoadingPdf />,
  };

  useEffect(() => {
    const currentType = type !== 'default' ? type : typeUpload;
    setMyState(currentType);
    if (currentType === 'loading' && user.user) {
      const timeout = setTimeout(() => {
        router.push('/signature');
      }, 1000);
      return () => clearTimeout(timeout);
    }
    if (currentType === 'loading' && !user.user) {
      setIsOpen(true);
    }
  }, [type, typeUpload]);

  const state = lookupState[myState] || (
    <DropAndUpload
      inputRef={inputRef}
      handleFileChange={handleFileChange}
      triggerFileUpload={triggerFileUpload}
    />
  );

  return (
    <>
      <div
        className={`${type === 'dragging' && 'cursor-grab'} -z-10`}
        ref={dropZoneRef}
      >
        {state}
      </div>
      <SignatureBeforeLogin
        setTypeUpload={pdfDataUpload === '' ? setType : setTypeUpload}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        pdfName={pdfData !== '' ? pdfData : pdfDataUpload}
      />
    </>
  );
};
