import { Icon } from '@/components/atoms/Icons';
import { Animation } from '@/components/atoms/Animations';

export const LoadingPdf = () => {
  return (
    <div className="relative">
      <div className="rounded-[1.25rem] bg-white relative p-3 max-mobile-md:w-[85%]  max-tablet-md:w-[80%] max-desktop-xl:w-[70%] w-[55.6%] transition-width duration-300 ease-in-out mx-auto shadow-lg">
        <Icon
          className="absolute bottom-0 -z-10 translate-x-[-4rem] translate-y-[3.4rem]"
          width="9rem"
          height="8rem"
          name="fourLineGray"
        />
        <Icon
          className="absolute bottom-0 right-0 -z-10 translate-x-[2.5rem] translate-y-16 rotate-90"
          width="8rem"
          height="8rem"
          name="pointDecorationYellow"
        />
        <div className="flex gap-10 max-tablet-sm:gap-0 max-tablet-sm:flex-col items-center h-auto justify-between px-6 rounded-[1.25rem] border-2 border-dashed py-10  max-tablet-sm:py-1 max-tablet-xs:py-0">
          <Icon
            width="5.9rem"
            height="6.8rem"
            name="pdfRed"
            className="flex-shrink-0 max-tablet-sm:scale-[0.8] max-tablet-sm:mt-2 max-mobile-lg:mt-0"
          />

          <div className="text-headingSm min-w-[15rem] w-full max-tablet-sm:text-center max-tablet-sm:py-1">
            <h1 className="font-bold font-trebuchet leading-8 text-[1.4rem]">
              Cargando el documento...
            </h1>
            <h1 className="mt-3 font-light text-neutral-50 font-nunito text-bodyXs">
              Por favor, espera mientras procesamos el archivo.
            </h1>
          </div>

          <div className="flex items-center justify-center w-[5rem] relative my-10">
            <Animation
              className="w-[10rem] h-[10rem] mr-[4rem] max-tablet-sm:mr-[0rem] absolute"
              name="loading"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
