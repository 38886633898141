import React, { useEffect, useRef, useState } from 'react';
import { Card } from '@/components/molecules/carousel/Card';
import { Icon } from '@/components/atoms/Icons';

interface DataItem {
  backgroundColor: string;
  icon: string;
  title: string;
  text: string;
}

const datos: DataItem[] = [
  {
    backgroundColor: 'bg-[#8FCDFE]',
    icon: 'pdfRed',
    title: 'Ayuda',
    text: 'Seguridad en las Firmas Digitales: Mejores Prácticas y Recomendaciones',
  },
  {
    backgroundColor: 'bg-[#F6F1D6]',
    icon: 'pdfRed',
    title: 'Ayuda',
    text: 'Gestión de Certificados Digitales en el Sistema de Firmas Digitales',
  },
  {
    backgroundColor: 'bg-[#ffffff]',
    icon: 'pdfRed',
    title: 'Ayuda',
    text: 'Integración del API de Firmas Digitales en tu Aplicación',
  },
  {
    backgroundColor: 'bg-[#ffffff]',
    icon: 'pdfRed',
    title: 'Ayuda',
    text: 'Personalización de la Experiencia de Firma Digital en tu Aplicación',
  },
  {
    backgroundColor: 'bg-[#ffffff]',
    icon: 'pdfRed',
    title: 'Ayuda',
    text: 'Protege tu identidad en la web, solicita usar Digito para tus transacciones online.',
  },
  {
    backgroundColor: 'bg-[#F6F1D6]',
    icon: 'pdfRed',
    title: 'Ayuda',
    text: 'Gestión de Certificados Digitales en el Sistema de Firmas Digitales',
  },
];

export const Carousel = () => {
  const myDivRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<DataItem[]>(datos);

  function moveLastToFirst() {
    setData((prevData) => {
      if (prevData.length === 0) return prevData;
      const nuevoArray = [...prevData];
      const ultimoElemento = nuevoArray.pop();
      if (ultimoElemento) {
        nuevoArray.unshift(ultimoElemento);
      }
      return nuevoArray;
    });
  }

  function moveFirstToLast() {
    setData((prevData) => {
      if (prevData.length === 0) return prevData;
      const nuevoArray = [...prevData];
      const primerElemento = nuevoArray.shift();
      if (primerElemento) {
        nuevoArray.push(primerElemento);
      }
      return nuevoArray;
    });
  }

  return (
    <div className="flex relative justify-center w-[82%] mx-auto -z-10">
      <div className="flex absolute items-center gap-4 left-0 top-12">
        <Icon
          width="1rem"
          height="1rem"
          name="arrowSimple"
          className="rotate-90 cursor-pointer"
          onClick={moveLastToFirst}
        />
        <h1 className="font-bold">Descubre lo que puedes hacer</h1>
      </div>
      <div className="flex absolute gap-4 right-0 top-12">
        <Icon
          width="1rem"
          height="1rem"
          name="arrowSimple"
          className="rotate-180 cursor-pointer"
          onClick={moveFirstToLast}
        />
        <Icon
          width="1rem"
          height="1rem"
          name="arrowSimple"
          className="cursor-pointer"
          onClick={moveLastToFirst}
        />
      </div>

      <div
        ref={myDivRef}
        className="flex gap-4 relative mt-20 w-full overflow-hidden"
      >
        {data.map((dato, index) => (
          <Card
            key={index}
            backgroundColor={dato.backgroundColor}
            icon={dato.icon}
            text={dato.text}
            title={dato.title}
          />
        ))}
        <div className="absolute right-0 -mr-3 top-0 h-full w-10 bg-gradient-to-l from-white to-transparent pointer-events-none blur-sm" />
      </div>
    </div>
  );
};
