import { HeroPdfStatus } from '@/components/molecules/signature/HeroPdfStatus';
import { Carousel } from '@/components/molecules/carousel/Carousel';
import { NavbaBackGround } from '@/components/molecules/navbar/NavbarBackground';

export const HomeLocal = () => {
  return (
    <div className="mt-[10rem] w-full mx-auto">
      <div className="absolute w-full translate-y-[-10rem]">
        <NavbaBackGround />
      </div>
      <div className="-mt-[6.8rem]  mx-auto">
        <HeroPdfStatus />
      </div>
      <div className="mt-10 mb-16">
        <Carousel />
      </div>
    </div>
  );
};
