import { useRef, useState } from 'react';
import { pdfToString } from './usePdftoStorage';

export const useFileUpload = () => {
  const [typeUpload, setTypeUpload] = useState('default');
  const [pdfDataUpload, setPdfDataUpload] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const validateFile = (file: File) => {
    const isValid = file.name.toLowerCase().endsWith('.pdf');
    const isFileSizeValid = file.size < 1024 * 1024 * 100; // 100 MB
    return isValid && isFileSizeValid;
  };

  const handleFileUpload = async (file: File) => {
    try {
      const pdfInfo = await pdfToString(file);
      if (pdfInfo) {
        setPdfDataUpload(pdfInfo.name);
      }
    } catch (error) {
      console.error('Error al procesar el PDF:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    const fileList = input.files;

    if (fileList && fileList.length > 0 && validateFile(fileList[0])) {
      setTypeUpload('loading');
      handleFileUpload(fileList[0]);
     
    } else {
      setTypeUpload('error');
    }
  };

  const triggerFileUpload = () => {
    inputRef.current?.click();
  };

  return {
    inputRef,
    handleFileChange,
    triggerFileUpload,
    typeUpload, 
    setTypeUpload,
    pdfDataUpload
  };
};
